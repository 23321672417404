import React, { Component } from 'react';
import { graphql, Link } from 'gatsby';
import AOS from 'aos';

import 'aos/dist/aos.css';
import styles from './index.module.styl';
import 'typeface-mali';

import jsonLd from '../data/jsonLd.json';
import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import TextUnderPictureEvent from '../components/text-under-picture-event/text-under-picture-event';

import { parseRef } from '../services/local-storage';

class IndexPage extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init({
      once: true,
      duration: 1000,
    });

    parseRef(this.props.location);
  }

  componentDidUpdate() {
    this.aos.refresh();
  }
  render() {
    let { data } = this.props;
    const jsonLdString = JSON.stringify(jsonLd);
    const venueJsonLd = JSON.stringify(
      data.allEventsJson.edges.reduce(
        (prev, { node }) => [...prev, JSON.parse(node.jsonLdString)],
        []
      )
    );
    return (
      <Layout data-aos="fade-right">
        <SEO
          title="Singles Dating Events | Match in Action"
          description="Dating events for singles. Dating should be fun. Your dream date might be just an event away!"
          meta={[
            {
              name: 'google-site-verification',
              content: 'zafKxAnR1bv_wc2Z0Z6QswS8xCPiKbHTStCeynFuB18',
            },
          ]}
          jsonLdString={jsonLdString}
        />
        <SEO title="Singles Dating Events | Match in Action" jsonLdString={venueJsonLd} />
        <Header />
        <section className={`row ${styles.container}`} data-aos="fade-up">
          {data.allEventsJson.edges.map(({ node }, index) => (
            <TextUnderPictureEvent
              key={node.id}
              subject={node.Subject}
              startTime={node.Start}
              archive={node.archive}
              text={node.FullText}
              series={node.Series}
              image={node.Image}
              momentTz={node.TimeZone}
              link={`/events/${node.id}/`}
            />
          ))}
        </section>
        <div className={styles.archiveLinkContainer}>
          <Link to={'/archive/'} data-aos="fade-up" className={styles.archiveLink}>
            Older events
          </Link>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    allEventsJson(filter: { archive: { eq: false } }, sort: { fields: [Start], order: ASC }) {
      edges {
        node {
          id
          Subject
          Series
          Start
          RSVP
          FullText
          TimeZone
          Image
          archive
          Start
          jsonLdString
        }
      }
    }
  }
`;

export default IndexPage;
